import { default as Vuex, Module, ActionContext, Action } from "vuex";
import lodash from "lodash";
import { openDB, DBSchema, IDBPDatabase, StoreNames } from "idb";
import { DataManager, ObjectStoreNames } from "@/data-manager";
import { UploadableModel } from "@/models/uploadable";
const dataManager: DataManager = new DataManager();


class DataState {
  
  hasAllHomelessItemsUpdated: boolean = false; //Should only be run once. Just to give all items with no locationGuid the current locationGuid set be the user
}


class DataModule implements Module<DataState, any> {
  namespaced = true;
  state: DataState = new DataState();
  mutations = {
    /*
    mutation(state: State, payload: any) {
      //no async calls
      state.data = payload;
    }
    */
  };
  actions = {
    /*
    action(context: ActionContext<State, any>) {
      //async calls allowed, action can also be async
      //context.state, context.rootState, context.dispatch, context.commit
    }
    */

    clearCribScoreDownloadedData(context: ActionContext<DataState, any>) {
      return dataManager.clearCribScoreDownloadedData();
    },
    clearCribScoreHistoryData(context: ActionContext<DataState, any>) {
      return dataManager.clearCribScoreHistoryData();
    },

    async addItemsToDb(
      context: ActionContext<DataState, any>,
      obj: { objectStoreName: ObjectStoreNames; items: any[]; progressCb?: (loaded: number, total: number) => void }
    ) {
        await dataManager.addItems(obj.objectStoreName, obj.items, obj.progressCb);
      },
    addCribScore(context: ActionContext<DataState, any>, item: UploadableModel) {
      return dataManager.addItems("saved-crib-scores", [item]);
    },
    addRationDMFs(context: ActionContext<DataState, any>, items: any[]){
      dataManager.addItems("ration-dmfs", items)
    },
    addCallIncrement(context: ActionContext<DataState, any>, callIncrement: number) {
      dataManager.setCallIncrement(callIncrement);
    },
    getCribScore(context: ActionContext<DataState, any>, guid: string) {
      return dataManager.getSavedCribScore(guid);
    },
    getCribScores(context: ActionContext<DataState, any>) {
      return dataManager.getItems("saved-crib-scores");
    },
    addTodaysCribScore(context: ActionContext<DataState, any>, item: any) {
      return dataManager.addItems("todays-crib-scores", [item]);
    },
    getTodaysCribScores(context: ActionContext<DataState, any>) {
      return dataManager.getItems("todays-crib-scores");
    },
    addCribScoreHistory(context: ActionContext<DataState, any>, item: any) {
      return dataManager.addItems("crib-scores-history", [item]);
    },
    getCribScoreHistory(context: ActionContext<DataState, any>) {
      return dataManager.getItems("crib-scores-history");
    },
    getRationDMFs(context: ActionContext<DataState, any>){
      return dataManager.getItems("ration-dmfs");
    },
    getCallIncrement(context: ActionContext<DataState, any>){
      return dataManager.getCallIncrement();
    },
    async updateAllHomelessItems(context: ActionContext<DataState, any>) {
      //skip if updateAllHomelessItems has already been run
      if (!context.state.hasAllHomelessItemsUpdated) {
        await dataManager.updateAllHomelessItems(context.rootState.user.location.guid);
        context.state.hasAllHomelessItemsUpdated = true;
      }
    }
  };
  getters = {
    /*
    getter(state: State, getters: any, rootState: any, rootGetters: any) {
      //return a function if you want the getter to receive input parameters
    }
    */
  };
};


const dataModule: DataModule = new DataModule();

export default dataModule;