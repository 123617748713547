






















































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { DimssaButton, ButtonState } from "@/components/shared/dimssa-button.vue";
import * as Model from "@gigalot/data-models";
import lodash from "lodash";
import moment from "moment";
import Decimal from "decimal.js";

@Component({
  components: {
    DimssaButton
  }
})
export default class Cribs extends Vue {
  @Prop() selectedKraal!: string;
  call : Decimal = new Decimal(-1);
  score = "";
  loading = false;

  decrement(seq: number) {
    this.call = this.call.minus(this.callIncrement);
    this.cribScores[this.getIndex(seq)].call = parseFloat(this.call.toFixed(1));
    this.edited = true;
  }

  increment(seq: number) {
    this.call = this.call.plus(this.callIncrement);
    this.cribScores[this.getIndex(seq)].call = parseFloat(this.call.toFixed(1));
    this.edited = true;
  }
  getScore(item: Model.CribReadingItem) {
    if (item.score === -1) {
      return item.status;
    } else return item.score;
  }

  gotoKraal(event: any) {
    this.$router.push(`/cribscores?selectedKraal=${event.kraalId}`);
    this.$router.go(0);
  }

  swipeDirection = "None";
  edited = false;
  cribScores: Model.CribReadingItem[] = [];
  cribHistory:any = [];
  currentCribHistory: any = [];
  page = 1;
  search = "";
  filter = {};
  sequence = 0;
  callIncrement :number = 1.0;
  rations: any[] = [];

  keys = ["Kraal", "Ration", "Score", "Call", "Drive Order"];
  itemsPerPage = 1;
  cribHistoryHeader = [
    { text: "Date", value: "readingDate", align: "center" },
    { text: "Score", value: "score", align: "center" },
    { text: "Call", value: "call", align: "center" },
    { text: "Fed", value: "actualFed", align: "center" }
  ];

  getColor(status: string) {
    switch (status) {
      case "waiting":
        return "orange";
      case "skipped":
        return "red";
      case "blocked":
        return "grey";
      case "done":
        return "green";
    }
  }
  mounted() {
    //TODO: Get dat from store instaed of calls (Should be in store from the cribSummary screen)
    //load kraal if kraal selected
    this.$store.dispatch("user/addFirebaseCallback", this.getCribPlansDb);
  }

  getDryMatterFactor(rationGuid:string){
    for (let i = 0; i < this.rations.length;i++){
      if (this.rations[i].guid === rationGuid){
        return this.rations[i].dryMatterFactor ? this.rations[i].dryMatterFactor : 1.0;
      }
    }
    return 1.0;
  }

  getTotalCall(rationGuid:string) {
    if (this.boundAnimals) {
      let a = new Decimal(this.boundcall);
      let b = new Decimal(this.boundAnimals);
      let c = a.mul(b);
        let dmf = this.getDryMatterFactor(rationGuid);
      let d = c.div(dmf);
    return d.toFixed(0);
    } else return 0;
  }

  get boundscore() {
    let index = this.getIndex(this.sequence);
    if (this.cribScores[index].score === -1) return "#";
    else return this.cribScores[index].score;
  }

  get boundcall() {
    let index = this.getIndex(this.sequence);
    if (this.cribScores[index].score === -1) {
      if (this.currentCribHistory.length > 0) {
        this.cribScores[index].call = this.currentCribHistory[this.currentCribHistory.length - 1].call;
        return this.currentCribHistory[this.currentCribHistory.length - 1].call;
      } else return this.cribScores[index].call;
    } else {
      return this.cribScores[index].call;
    }
  }

  get boundAnimals() {
    let index = this.getIndex(this.sequence);
    return this.cribScores[index].animalCount ? this.cribScores[index].animalCount : 0;
  }

  set boundcall(value: any) {
    let index = this.getIndex(this.sequence);
    this.call = value;
    this.cribScores[index].call = value;
    this.edited = true;
  }

  async getCribPlansDb() {
    this.loading = true;
    try {
      console.log("getCribPlansDb()");
      this.cribScores = await this.$store.dispatch("data/getTodaysCribScores");
      this.rations = await this.$store.dispatch("data/getRationDMFs");
      this.callIncrement = parseFloat(await this.$store.dispatch("data/getCallIncrement"));
      this.cribScores = lodash.sortBy(this.cribScores,function(o) { return o.kraalId; });
      console.log("Done Downloading");
      this.cribHistory = await this.$store.dispatch("data/getCribScoreHistory");
    } catch (err) {
      console.log("getCribPlans -> error: " + err);
    } finally {
      if (this.selectedKraal) {
        console.log(this.selectedKraal);
        let index = 0;
        let sequence = this.getSequence(this.selectedKraal);
        if (sequence > -1) {
          this.page = sequence + 1;
          this.sequence = sequence;
          let index2 = this.getIndex(sequence);
          this.currentCribHistory = this.getcribHistory(this.cribScores[index2].kraalId);
        }

        if (this.currentCribHistory.length > 0) {
          this.call = new Decimal(this.currentCribHistory[this.currentCribHistory.length - 1].call);
        }
        console.log(`setting page index to ${this.page}`);
      } else console.log("nothing in selected Kraal");

      this.loading = false;
      //default data
    }
  }

  getIndex(sequence: number) {
    let index = lodash.findIndex(this.cribScores, { sequence: sequence });
    return index;
  }
  getpageIndex(kraalId: string) {
    let index = lodash.findIndex(this.cribScores, { kraalId: kraalId }) + 1;

    return index;
  }
  getSequence(kraalId: string) {
    
    let seq = -1;
    let item = lodash.find(this.cribScores, { kraalId: kraalId });
    if (item) {
      if (item.sequence != undefined) {
        return item.sequence;
      }
    } else return seq;
    return seq;
  }

  getcribHistory(kraalId: string) {
      let history: any[] = [];
    for (let day of this.cribHistory){
      try{
        let hs = lodash.find(day.cribScores, { kraalId: kraalId });
        if (hs){
          history.push(hs);
        }
        
      }catch(err){
    
        console.warn(`Kraal ${kraalId} had no feed amount for day`)
      }

    }
    return history;
  }

  async updateServer(editedItem?: any) {
    console.log(editedItem);
    if (editedItem) {
      editedItem.actualFed = 0;
      try {
        console.log("updateServer()");
        let json = await this.$store.dispatch("cribScores/save", editedItem);
       
      } catch (err) {
        console.log(err);
        throw err;
      }
    }
  }

  get moment() {
    return this.$store.state.moment;
  }

  get numberOfPages() {
    return Math.ceil(this.cribScores.length / this.itemsPerPage);
  }

  nextPage() {
    let index = this.getIndex(this.sequence);
    //save crib score before going to next page (if something changed)
    if (this.edited) this.updateServer(this.cribScores[index]);

    if (this.sequence <= this.numberOfPages) this.sequence += 1;
    if (this.page + 1 <= this.numberOfPages) this.page += 1;
    //goto summary page
    else this.$router.push(`/outbox`);

    index = this.getIndex(this.sequence);
    this.currentCribHistory = this.getcribHistory(this.cribScores[index].kraalId);
    this.call = new Decimal(this.boundcall);
    this.score = "";
    this.edited = false;
  }

  formerPage() {
    let index = this.getIndex(this.sequence);
    if (this.edited) this.updateServer(this.cribScores[index]);

    if (this.page > 1) this.page -= 1;
    if (this.sequence > 0) this.sequence -= 1;
    index = this.getIndex(this.sequence);
    this.currentCribHistory = this.getcribHistory(this.cribScores[index].kraalId);
    this.call = new Decimal(this.boundcall);
    this.score = "";
    this.edited = false;
  }

  updateItemsPerPage(number: number) {
    this.itemsPerPage = number;
  }

  back(seq: number) {
    this.sequence = seq;
    this.formerPage();
  }

  block(seq: number) {
    //Set kraal status to blocked
    let index = this.getIndex(seq);
    this.cribScores[index].status = "blocked";
    this.edited = true;
    this.sequence = seq;
    this.nextPage();
  }

  skip(seq: number) {
    //Set kraal status to skipped
    let index = this.getIndex(seq);
    this.cribScores[index].status = "skipped";
    this.edited = true;
    this.sequence = seq;
    this.nextPage();
  }

  select(score: string, seq: number) {
    this.score = score;
    let index = this.getIndex(seq);
    this.cribScores[index].score = parseInt(score);
    this.cribScores[index].status = "done";
    this.edited = true;
  }
}
