import Vue from "vue";
import Vuex, { ActionContext } from "vuex";
import createPersistedState from "vuex-persistedstate";
import user from "./modules/user";
import data from "./modules/data";
import * as packageJson from "../../package.json";
import * as moment from "moment";
import "moment/locale/en-gb";
import list from "./modules/list";
import popup from "./modules/popup";
import upload from "./modules/upload";
import notes from "./modules/notes";
import cribScores from "./modules/crib-score";
import { createClient } from "@/helpers/graphql-ws-rtc-adapter";
import * as RtcClient from "@gigalot/rtc-client";
import { v4 } from "uuid";


const vuexMapFields = require("vuex-map-fields");
const getField = vuexMapFields.getField;
const updateField = vuexMapFields.updateField;
const mapFields = vuexMapFields.mapFields;
const mapMultiRowFields = vuexMapFields.mapMultiRowFields;

const dataChannelPromises: { [messageId: string]: { resolve: any; reject: any } } = {};
export { mapFields };

Vue.use(Vuex);

type EnvType = "production" | "staging" | "testing" | "undetermined";

class RootState {
  environment: EnvType = "undetermined";
  version: string = packageJson.version;
  moment: any = moment;
  lightDarkMode: "light" | "dark" = "dark";
  storage: any = {};
  appBarText: string = "";
  snackbarMessage: string = "";
  snackbarVisible: boolean = false;
  wsDialogVisible: boolean = false;
  activeNotices: any[] = [];
  dataChannel: any = {};


  rtcSignalling: "disconnected" | "local" | "cloud" = "disconnected";
  useP2PConn = false;
  peerConnection = "";
  localStream = "";
  remoteStream = "";
  roomDialog = "";
  roomId = ``;
  message = "";
  messageId = 0;
  messageResponse = "";

}

async function graphQl(jwt: string, url: string, query: string, variables: any, useRtc: boolean) {
  if (!useRtc) {
    let options: RequestInit = {
      method: "POST",
      headers: { "Content-Type": "application/json", Accept: "application/json", Authorization: "Bearer " + jwt },
      body: JSON.stringify({ query: query, variables: variables })
    };
    let response = await fetch(url, options);

    if (response.ok) {
      let json = await response.json();

      console.log("json received");
      //console.log(json);
      return json;
    } else {
      let errorJson = await response.json();
      let s = "";
      for (let error of errorJson.errors) {
        s += `\n${error.message}`;
      }
      throw Error("Response not ok: " + s);
    }
  } /*useRtc*/ else {
    // let dataChannel = await RtcClient.getDataChannel();
    // if (!dataChannel) dataChannel = await RtcClient.getDataChannel();
    // if (!dataChannel) throw Error("Could not get dataChannel");

    // const messageId = v4();

    // const json = {
    //   messageType: "graphql-mutations-queries",
    //   messageId: messageId,
    //   resolver: "feeder",
    //   query: query,
    //   variables: variables
    // };

    // const promise = new Promise((resolve, reject) => {
    //   dataChannelPromises[messageId] = { resolve, reject };
    // });

    // (RtcClient.onmessage as any) = (event: any) => {
    //   //determine whether to resolve or reject the promise based on messageId
    //   console.dir(event);
    //   const message: { messageId?: string, ok?: boolean, json?: any, err?: any; } = JSON.parse(event.data);
    //   if (!message.messageId && message.err)
    //     // If no messageId but err then RTC had an internal error
    //     throw Error(`RTC error: ${message.err}`);
    //   else if (message.messageId && message.ok)
    //     dataChannelPromises[message.messageId].resolve(message.json);
    //   else if (message.messageId && !message.ok)
    //     dataChannelPromises[message.messageId].reject(message.err ?? message.json.errors.map((e: any) => e.message).join(". ") ?? "unknown error");
    //   else throw Error(`RTC error: message received with no messageId and no error: ${JSON.stringify(message)}`);
    // };

    // (RtcClient.onerror as any) = (err: any) => console.error("RtcClient.onerror: " + err);

    // RtcClient.send(json);

    const client = createClient();
    const promise = new Promise((resolve, reject) => {
      let result: any;
      client?.subscribe(
        {
          query: query,
          variables: variables,
        },
        {
          next: (data: any) => {
            result = data;
          },
          error: reject,
          complete: () => {
            resolve(result);
          }
        }
      );
    });

    return promise;
  }
}


export default new Vuex.Store<RootState>({
  state: new RootState(),
  mutations: {
    store(state: any, payload: { [key: string]: any }) {
      for (let key in payload) {
        //state.storage[key] = payload[key];
        //state[key] = payload[key];
        updateField(state, { path: key, value: payload[key] });
      }
    },
    snackbar(state: RootState, payload: { message: string }) {
      state.snackbarMessage = payload.message;
      state.snackbarVisible = true;
    },
    snackbarHide(state: RootState) {
      state.snackbarVisible = false;
    },
    dialogHide(state: RootState) {
      state.wsDialogVisible = false;
    },
    rtcSignalling(state: RootState, payload: "disconnected" | "local" | "cloud") {
      state.rtcSignalling = payload;
    },
    // updateField(state: any, field: { path: string; value: any }) {
    //   //let uid = state.user.user.uid;
    //   //let guid = state.user.location.guid;
    //   //state.storage[uid] = state.storage[uid] || {};
    //   //state.storage[uid][guid] = state.storage[uid][guid] || {};
    //   updateField(state.storage, field);
    // },
    updateField(state, field) {
      updateField(state, field)
    },
    environment(state: RootState, payload: EnvType) {
      state.environment = payload;
    },
    lightDarkMode(state: any, payload: "light" | "dark") {
      state.lightDarkMode = payload;
    },
    version(state: any) {
      state.version = packageJson.version;
    }
  },
  actions: {
    async graphQl(
      context: ActionContext<RootState, any>,
      o: { gql: string; variables?: { [key: string]: any }; jwt: string; destination?: "feeder-server" | "node" | "cloud" }
    ) {
      let backendUrl = "";
      if (o.destination === "cloud") {
        backendUrl = context.getters["cloudUrl"]();
      } else {
        backendUrl = context.getters["backendUrl"]();
      }
      console.log(o.gql.split("\n")[0]);

      if (o.destination === "cloud") {
        return graphQl(o.jwt, `${backendUrl}/feeder`, o.gql, o.variables, false);
      } else {
        if (this.state.useP2PConn) {
          return graphQl(o.jwt, `${backendUrl}/feeder`, o.gql, o.variables, true);
        }
        else {
          return graphQl(o.jwt, `${backendUrl}/feeder`, o.gql, o.variables, false);
        }

      }
    }
  },
  getters: {
    getField,
    storage(state: any) {
      return () => {
        return state;
      };
    },
    dark(state: any) {
      return () => {
        return state.lightDarkMode === "dark";
      };
    },
    cloudUrl(state: RootState) {
      return () => {
        switch (state.environment) {
          case "production":
            return "https://europe-west1-gigalot-cloud.cloudfunctions.net/feederAppResolver";
          case "staging":
            return "https://europe-west1-gigalot-testing.cloudfunctions.net/feederAppResolver";
          case "testing":
            return "http://pi.gigalot.systems:8083";
          case "undetermined":
          default:
            throw Error("No environment detected! (Expected to be production, staging, or testing)");
        }
      };
    },
    backendUrl(state: RootState, getters: any, rootState: any, rootGetters: any) {
      return () => {
        switch (state.environment) {
          case "production":
            return "https://pi.gigalot.systems:7777";
          case "staging":
            return "https://pi.gigalot.systems:7777";
          case "testing":
            return "https://pi.gigalot.systems:7777";
          case "undetermined":
          default:
            throw Error("No environment detected! (Expected to be production, staging, or testing)");
        }
      };
    },
    functionsUrl(state: RootState, getters: any, rootState: any, rootGetters: any) {
      return () => {
        switch (state.environment) {
          case "production":
            return "https://pi.gigalot.systems:7777";
          case "staging":
            return "https://pi.gigalot.systems:7777";
          case "testing":
            return "https://pi.gigalot.systems:7777";
          case "undetermined":
          default:
            throw Error("No environment detected! (Expected to be production, staging, or testing)");
        }
      };
    }
  },
  modules: {
    user,
    list,
    popup,
    data,
    upload,
    notes,
    cribScores
  },
  plugins: [createPersistedState()]
});
