
























































































import { Component, Vue } from "vue-property-decorator";
import {
  DimssaButton,
  ButtonState
} from "@/components/shared/dimssa-button.vue";
import { addRtcReadyCallback } from "../main";
import Setup from "@/views/Setup.vue";

@Component({
  components: {
    DimssaButton
  }
})
export default class Home extends Setup {
  async mounted(){
   //  this.$store.dispatch("user/addFirebaseCallback", this.getData);
       addRtcReadyCallback(() => this.getData());
  }
 
  async getData(){  
    await this.kraals(false);
    await this.tractors(false);
    await this.trailers(false);
    await this.operators(false);
    }
 }
