



























































































import { Component, Vue } from "vue-property-decorator";
import { DimssaButton, ButtonState } from "@/components/shared/dimssa-button.vue";
import colors from 'vuetify/lib/util/colors'
import lodash from "lodash";
import * as Model from "@gigalot/data-models";

const { gmapApi } = require("vue2-google-maps");
const Subscriber = require("@jetblack/graphql-reconnect-client");
import { createClient } from "@/helpers/graphql-ws-rtc-adapter";

@Component({
  components: {
    DimssaButton
  }
})
export default class Map extends Vue {
  name = "Map";

  icon = {
    url: "./img/tractor.png",
    scaledSize: { width: 50, height: 50 }
  };
  tractorInfo = [];
  gpsCoordinates = [];
  gpsCenter: any = { "lat": 0, "lng": 0 };
  loading = false;
  replays: any = undefined;
  kmlFilename = "";
  playbackDate = "";
  date = new Date().toISOString().substr(0, 10);
  menu = false;
  headers = [
    {
      text: "Tractor",
      value: "TractorID",
      align: "center",
      sortable: true,
      filterable: true,
      divider: false,
      group: false
    },
    {
      text: "Trailer",
      value: "TrailerID",
      align: "center",
      sortable: true,
      filterable: true,
      divider: true
    },
    {
      text: "Ration",
      value: "Ration",
      align: "center",
      sortable: true,
      filterable: true,
      divider: true
    },

    {
      text: "Status",
      value: "State",
      align: "center",
      sortable: true,
      filterable: true,
      divider: true
    },
    {
      text: "Weight",
      value: "ScaleWeight",
      align: "center",
      sortable: true,
      filterable: true,
      divider: true
    },
    {
      text: "Job Amount (kg)",
      value: "JobWeight",
      align: "center",
      sortable: true,
      filterable: true,
      divider: true
    }
  ];
  urlws = "wss://pi.gigalot.systems:7778/api/ws";
  options = {};
  coordinateSubscription = `subscription($guid:String!) {
    gpsLocations(guid:$guid)
    }`;
  coordinateQuery = `query($guid:String!){
    GPSLocations(guid:$guid)
    }`;
  tractorQuery = `query($guid:String!){
    TractorInfo(guid:$guid)
    }`;
  tractorSubscription = `subscription($guid:String!) {
    tractorInfo(guid:$guid)
    }`;
  variables = { guid: this.$store.state.user.location.guid };
  operationName = null;

  setMapPoint(item: any) {
    console.log(item);
    let coordination: any = lodash.find(this.gpsCoordinates, { ID: item.TractorID });
    console.log(coordination?.latitude);
    console.log(coordination?.longitude);
    if (coordination?.latitude && coordination?.longitude) {
      let mapPromise: any = this.$refs.mapRef;
      mapPromise.$mapPromise.then((map: any) => {
        map.panTo(this.getLatLong(coordination.latitude, coordination.longitude));
        map.setZoom(20);
      });
    }
  }

  colorPalette = [
    colors.red,
    colors.green,
    colors.amber,
    colors.purple,
    colors.indigo,
    colors.teal,
    colors.lime,
    colors.deepOrange,
    colors.brown,
    colors.blueGrey
  ];
  flightpaths: any[] = [];

  drawPolyline() {
    let palIndex = 0;
    let grouped = lodash.groupBy(this.replays, "TractorID");

    for (let group in grouped) {
      let coordinates = [];
      let sorted = lodash.sortBy(grouped[group], function(o) { return o.TimeTicks; });
      for (let item of sorted) {
        let track = { lat: item.Latitude, lng: item.Longitude };
        coordinates.push(track);
      }
      let gthis: any = globalThis;
      const flightPath = new gthis.google.maps.Polyline({
        path: coordinates,
        geodesic: true,
        strokeColor: this.colorPalette[palIndex++].base,
        strokeOpacity: 1.0,
        strokeWeight: 3
      });
      let mapPromise: any = this.$refs.mapRef;
      mapPromise.$mapPromise.then((map: any) => {
        flightPath.setMap(map);
      });
      this.flightpaths.push(flightPath);
    }
  }

  clearTracks() {
    let mapPromise: any = this.$refs.mapRef;
    mapPromise.$mapPromise.then((map: any) => {
      for (let flightPath of this.flightpaths) {
        flightPath.setMap(null);
      }
    });
  }

  async getGPSCenter() {
    let url = `${this.$store.getters["backendUrl"]()}/gpsCenter`;
    let jwt = await this.$store.dispatch("user/getOnlineIdToken", undefined, { root: true });
    let res = await fetch(url, {
      method: "GET",
      headers: new Headers({ "Authorization": `Bearer ${jwt}` }),
    });
    if (!res.ok) throw Error("Response OK : " + (await res.text()));
    let data = await res.json();
    console.log(data);
    this.gpsCenter = data;
  }
  getLatLong(lat: string, long: string) {
    try {
      let latitude = parseFloat(lat);
      let longitude = parseFloat(long);
      return { lat: latitude, lng: longitude };
    } catch (err) {

    }
  }

  async mounted() {
    setTimeout(() => {
      this.getKmlFile().then(() => { this.loadKmlLayer();  this.getGPSCenter();});
      this.getTractorInfo();
      this.getGPSCoordinates();
      this.getTractorInfoSocket();
      this.getGPSCoordinatesSocket();
      this.getReplayTracking(this.playbackDate);
    }, 3000);
  //   await this.$store.dispatch("user/addFirebaseCallback", this.getGPSCenter);
  //   await this.$store.dispatch("user/addFirebaseCallback", this.getKmlFile);
  //   await this.$store.dispatch("user/addFirebaseCallback", this.getTractorInfo);
  //   await this.$store.dispatch("user/addFirebaseCallback", this.getGPSCoordinates);
  //   await this.$store.dispatch("user/addFirebaseCallback", this.getTractorInfoSocket);
  //   await this.$store.dispatch("user/addFirebaseCallback", this.getGPSCoordinatesSocket);
  //   await this.$store.dispatch("user/addFirebaseCallback", this.loadKmlLayer);
  //   await this.$store.dispatch("user/addFirebaseCallback", this.getReplayTracking);
  // }
  }
  loadKmlLayer() {
    let mapPromise: any = this.$refs.mapRef;
    mapPromise.$mapPromise.then((map: any) => {
      let src = "https://feeder.gigalot.systems/kml/" + this.kmlFilename; //'https://developers.google.com/kml/documentation/KML_Samples.kml';
      let gthis: any = globalThis;
      var kmlLayer: google.maps.KmlLayer = new gthis.google.maps.KmlLayer(src, {
        suppressInfoWindows: false,
        preserveViewport: false,
        map: map
      });

      kmlLayer.addListener("click", (kmlEvent: google.maps.KmlMouseEvent) => {
        console.log(kmlEvent.featureData.name);
        let mapPromise: any = this.$refs.mapRef;
        mapPromise.$mapPromise.then((map: any) => {
          this.infowindow.open(map, kmlLayer);
        });
      });
    });
  }
  infowindow = new google.maps.InfoWindow({
    content: "<div>TEST</div>"
  });

  async getKmlFile() {
    let url = `${this.$store.getters["backendUrl"]()}/kmlFilename`;
    let jwt = await this.$store.dispatch("user/getOnlineIdToken", undefined, { root: true });
    let res = await fetch(url, {
      method: "GET",
      headers: new Headers({ "Authorization": `Bearer ${jwt}` })
    });
    if (!res.ok) throw Error("Response OK : " + (await res.text()));
    let data = await res.json();
    console.log(data);
    this.kmlFilename = data;
  }

  getTrailerName(guid: string) {
    let trailers = this.$store.getters[`storage`]().Trailers as Model.Trailer[];
    let trailer = lodash.find(trailers, { electronicID: guid });
    if (trailer) return trailer.wagonRegistration;
  }

  getTractorName(guid: string) {
    let tractors = this.$store.getters[`storage`]().Tractors as Model.Tractor[];
    let tractor = lodash.find(tractors, { electronicID: guid });
    if (tractor) return tractor.registrationNumber;
  }

  graphQLSubscriber: any = undefined;
  graphQLUnsubscribe: any = undefined;
  graphQLSubscriber2: any = undefined;
  graphQLUnsubscribe2: any = undefined;

  destroyed() {
    if (this.graphQLUnsubscribe) {
      this.graphQLUnsubscribe();
    }
    if (this.graphQLSubscriber) {
      this.graphQLSubscriber();
    }
    if (this.graphQLUnsubscribe2) {
      this.graphQLUnsubscribe2();
    }
    if (this.graphQLSubscriber2) {
      this.graphQLSubscriber2();
    }
  }

  async getTractorInfoSocket() {
    if (this.$store.state.useP2PConn) {
      await this.getOnlineTractorInfoSocket();
    } else {
      await this.getOnsiteTractorInfoSocket();
    }
  }

  async getOnlineTractorInfoSocket() {
    let variables = { guid: this.$store.state.user.location.guid, user: this.$store.state.user.user.uid };
    const subscribe = (subscriptions: string, variables: any, callback: (error: any, data: any) => Promise<void>) => {
      const client = createClient();
      let unsubscribe = client?.subscribe(
        {
          query: subscriptions,
          variables: variables
        },
        {
          next: (message: any) => callback(undefined, message.data),
          error: (err: any) => {
            throw err;
          },
          complete: () => console.log("subscription completed"),
        }
      );
      return unsubscribe;
    };

    this.graphQLUnsubscribe = subscribe(this.tractorSubscription, variables, async (error: any, data: any) => {
      this.$store.state.wsConnected = true;
      if (!(error || subscribe)) {
        console.log("subscribe success");
        this.$store.state.wsDialogVisible = false;
        // Normal closure
        return;
      }
      if (error) {
        console.log("error subscribing");

        this.$store.state.wsConnected = false;
        if (error.event.type === "close") { ; } else {
          this.$store.state.wsDialogVisible = true;
          console.error(error);
          //    throw error;
        }
      }
      this.$store.state.wsDialogVisible = false;
      this.$store.state.wsConnected = true;
      console.log("almost there");
      if (data) this.tractorInfo = JSON.parse(data.tractorInfo);
      this.loading = false;
    });
  }

  async getGPSCoordinatesSocket() {
    if (this.$store.state.useP2PConn) {
      await this.getOnlineGPSCoordinatesSocket();
    } else {
      await this.getOnsiteGPSCoordinatesSocket();
    }
  }

  async getOnsiteTractorInfoSocket() {
    this.graphQLSubscriber = Subscriber.graphQLReconnectingSubscriber(
      this.urlws,
      this.options,
      (error: any, subscribe: any) => {
        try {
          if (!(error || subscribe)) {
            // Normal closure.
            console.log("connected to socket");
            this.$store.state.wsDialogVisible = false;
            return;
          }
          if (error) {
            console.log("error connecting to socket");
            if (error.event.type === "close") { ; } else {
              this.$store.state.wsDialogVisible = true;
              console.error(error);
            }
          }
          console.log("socket connected");
          this.graphQLUnsubscribe = subscribe(this.tractorSubscription, this.variables, this.operationName, (error: any, data: any) => {
            if (!(error || subscribe)) {
              console.log("subscribe success");
              // Normal closure
              this.$store.state.wsDialogVisible = false;
              return;
            }
            if (error) {
              console.log("error subscribing");
              if (error.details[0].message === "close") { ; } else {
                this.$store.state.wsDialogVisible = true;
                console.error(error.details[0]);
              }
            }
            this.$store.state.wsDialogVisible = false;
            if (data) this.tractorInfo = JSON.parse(data.tractorInfo);
            this.loading = false;
          });
        } catch (err) {
          //show offline maybe
          // this.$store.state.wsDialogVisible = true;
        }
      },
      15000,
      3,
      "graphql-ws"
    );
  }

  async getOnsiteGPSCoordinatesSocket() {
    this.graphQLSubscriber2 = Subscriber.graphQLReconnectingSubscriber(
      this.urlws,
      this.options,
      (error: any, subscribe: any) => {
        try {
          if (!(error || subscribe)) {
            // Normal closure.
            console.log("connected to socket");
            this.$store.state.wsDialogVisible = false;
            return;
          }
          if (error) {
            console.log("error connecting to socket");
            if (error.details[0].message === "close") { ; }
            else {
              this.$store.state.wsDialogVisible = true;
              console.error(error);
            }
          }
          console.log("socket connected");
          this.graphQLUnsubscribe2 = subscribe(this.coordinateSubscription, this.variables, this.operationName, (error: any, data: any) => {
            if (!(error || subscribe)) {
              console.log("subscribe success");
              // Normal closure
              this.$store.state.wsDialogVisible = false;
              return;
            }
            if (error) {
              console.log("error subscribing");
              if (error.details[0].message === "close") { ; }
              else {
                this.$store.state.wsDialogVisible = true;
                console.error(error.details[0]);
              }
            }
            this.$store.state.wsDialogVisible = false;
            if (data) this.gpsCoordinates = JSON.parse(data.gpsLocations);
            this.loading = false;
          });
        } catch (err) {
          //show offline maybe
          //  this.$store.state.wsDialogVisible = true;
        }
      },
      15000,
      3,
      "graphql-ws"
    );
  }

  async getOnlineGPSCoordinatesSocket() {
    let variables = { guid: this.$store.state.user.location.guid, user: this.$store.state.user.user.uid };
    const subscribe = (subscriptions: string, variables: any, callback: (error: any, data: any) => Promise<void>) => {
      const client = createClient();
      let unsubscribe = client?.subscribe(
        {
          query: subscriptions,
          variables: variables
        },
        {
          next: (message: any) => callback(undefined, message.data),
          error: (err: any) => {
            throw err;
          },
          complete: () => console.log("subscription completed")
        }
      );
      return unsubscribe;
    };

    this.graphQLUnsubscribe = subscribe(this.coordinateSubscription, variables, async (error: any, data: any) => {
      this.$store.state.wsConnected = true;
      if (!(error || subscribe)) {
        console.log("subscribe success");
        this.$store.state.wsDialogVisible = false;
        // Normal closure
        return;
      }
      if (error) {
        console.log("error subscribing");

        this.$store.state.wsConnected = false;
        if (error.event.type === "close") { ; }
        else {
          this.$store.state.wsDialogVisible = true;
          console.error(error);
          //    throw error;
        }
      }
      this.$store.state.wsDialogVisible = false;
      this.$store.state.wsConnected = true;
      console.log("almost there");
      if (data) this.gpsCoordinates = JSON.parse(data.gpsLocations).GetGPSCoordinatesResult;
      this.loading = false;
    });
  }

  async getTractorInfo() {
    this.loading = true;
    try {
      console.log("getTractorInfo()");
      let gql = this.tractorQuery;
      let json = await this.$store.dispatch("graphQl", {
        gql,
        variables: { guid: this.$store.state.user.location.guid },
        url: "https://pi.gigalot.systems:7777/feeder"
      });
      console.log("Done Downloading");
      this.tractorInfo = JSON.parse(json.data.TractorInfo);
    } catch (err) {
      console.log("getTractorInfo -> error: " + err);
    }
  }

  async getGPSCoordinates() {
    this.loading = true;
    try {
      console.log("getGPSCoordinates()");
      let gql = this.coordinateQuery;
      let json = await this.$store.dispatch("graphQl", {
        gql,
        variables: { guid: this.$store.state.user.location.guid },
        url: "https://pi.gigalot.systems:7777/feeder"
      });
      console.log("Done Downloading");
      this.gpsCoordinates = JSON.parse(json.data.GPSLocations);
    } catch (err) {
      console.log("getGPSCoordinates -> error: " + err);
    }
  }

  async getReplayTracking(playbackDate: string) {
    if (!playbackDate) playbackDate = new Date(Date.now()).toISOString().split("T")[0];
    this.clearTracks();
    console.log(playbackDate);
    let url = `${this.$store.getters["functionsUrl"]()}/replayTracking?startDate=${playbackDate}&endDate=${playbackDate}`;
    let jwt = await this.$store.dispatch("user/getOnlineIdToken", undefined, {
      root: true
    });
    let res = await fetch(url, {
      method: "GET",
      mode: "cors",
      headers: new Headers({ "Authorization": `Bearer ${jwt} `}),
    });
    if (!res.ok) throw Error("Response OK : " + (await res.text()));
    let data = await res.json();
    console.log(data);
    this.replays = data;
    this.drawPolyline();
  }

  get google() {
    return gmapApi;
  }
}
