

















































import { Component, Prop, Vue } from "vue-property-decorator";
import DimssaButton from "@/components/shared/dimssa-button.vue";

import lodash from "lodash";
import { factory } from "@gigalot/data-models";
import Header from "@/data-table-header";
import * as Models from "@gigalot/data-models";

@Component({
  components: {
    DimssaButton
  }
})
export default class List extends Vue {
  @Prop() urlParam!: string;
  @Prop() setPath!: string; //path to set selected item to
  @Prop() continueRoute!: string; //url path to go to when continue is pressed

  get list() {
    return this.$store.state.list;
  }

  onClickEdit() {
    let list = this.$store.state.list;
    let item = this.selected[0] ? this.selected[0] : factory(list.typename);
      this.$store.commit("updateField", {
      path: this.list.onEdit.listUponEditPath,
      value: item
    });
    this.$router.push({ name: list.onEdit.routeName });
  }

  onContinue() {
     this.$store.commit("updateField", {
      path: this.list.onContinue.listUponSelectionSetPath,
      value: this.selected[0]
    });
     this.$router.push({ name: this.list.onContinue.routeName });
  }

  search: string = "";
  selected: any[] = [];

  async created() {
    this.selected = [];
switch (this.urlParam) {
      case "kraals":
        this.kraals();
        break;
      case "materials":
        this.materials();
        break;
      case "loaders":
        this.loaders();
        break;
      case "operators":
        this.operators();
        break;
      case "rations":
        this.rations();
        break;
      case "complexes":
        this.complexes();
        break;
      case "lines":
        this.lines();
        break;
      case "tractors":
        this.tractors();
        break;
      case "trailers":
        this.trailers();
        break;
      default:
        this.$store.commit("list/list", {
          title: "",
          headers: [],
          items: [],
          typename: ""
         });
        throw Error(`TODO: add ${this.urlParam} (this.urlParam) to List.vue's logic.`);
    }

    if (this.setPath !== undefined && this.continueRoute !== undefined) {
      this.$store.commit("list/onContinue", {
        routeName: this.continueRoute,
        listUponSelectionSetPath: this.setPath
      });
    } else {
      this.$store.commit("list/onContinue", undefined);
    }
  }

  async kraals() {
    this.$store.commit("list/list", {
      title: "Kraals",
      headers: [
        new Header({ text: "Kraal", value: "kraalId" }),
        new Header({ text: "Description", value: "description" }),
        new Header({ text: "Type", value: "kraalType" }),
        new Header({ text: "Capacity", value: "recommendedCapacity" }),
        new Header({ text: "Active", value: "active"})
      ],
      items: this.$store.getters["storage"]().Kraals as Models.Kraal[],
      typename: "Kraal",
      onEdit: { routeName: "kraal", listUponEditPath: "kraal" }
    });
  }

  async complexes() {
    this.$store.commit("list/list", {
      title: "Complexes",
      headers: [
        new Header({ text: "Complex", value: "code" }),
        new Header({ text: "Name", value: "displayName" }),
        new Header({ text: "Description", value: "description" })
      ],
      items: this.$store.getters["storage"]().Complexes as Models.Complex[],
      typename: "Complex",
      onEdit: { routeName: "complex", listUponEditPath: "complex" }
    });
  }

  async lines() {
    this.$store.commit("list/list", {
      title: "Lines",
      headers: [new Header({ text: "Line", value: "code" }), new Header({ text: "Description", value: "displayName" })],
      items: this.$store.getters["storage"]().Lines as Models.Line[],
      typename: "Line",
      onEdit: { routeName: "line", listUponEditPath: "line" }
    });
  }

  async tractors() {
    this.$store.commit("list/list", {
      title: "Tractors",
      headers: [
        new Header({ text: "Registration", value: "registrationNumber" }),
        new Header({ text: "ID", value: "electronicID" }),
        new Header({ text: "Operating", value: "isOperating" }),
        new Header({ text: "Default Ration", value: "defaultRation" }),
        new Header({ text: "Emergency Ration", value: "emergencyRation" })
      ],
      items: this.$store.getters["storage"]().Tractors as Models.Tractor[],
      typename: "Tractor",
      onEdit: { routeName: "tractor", listUponEditPath: "tractor" }
    });
  }

  async trailers() {
    this.$store.commit("list/list", {
      title: "Trailers",
      headers: [
        new Header({ text: "Wagon Registration", value: "wagonRegistration" }),
        new Header({ text: "Electronic ID", value: "electronicID" }),
        new Header({ text: "Min Capacity", value: "minCapacity" }),
        new Header({ text: "Max Capacity", value: "maxCapacity" }),
        new Header({ text: "Wagon Type", value: "wagonType" }),
        new Header({ text: "Delivery Side", value: "deliverySide" })
      ],
      items: this.$store.getters["storage"]().Trailers as Models.Trailer[],
      typename: "Trailer",
      onEdit: { routeName: "trailer", listUponEditPath: "trailer" }
    });
  }

  async operators() {
    this.$store.commit("list/list", {
      title: "Operators",
      headers: [
        new Header({ text: "Name", value: "operatorFullName" }),
        new Header({ text: "Employee Code", value: "operatorID" }),
        new Header({ text: "Electronic ID", value: "electronicID" }),
        new Header({ text: "Autorization Level", value: "authorizationLevel" })
      ],
      items: this.$store.getters["storage"]().Operators as Models.Operator[],
      typename: "Operator",
      onEdit: { routeName: "operator", listUponEditPath: "operator" }
    });
  }

  async loaders() {
    this.$store.commit("list/list", {
      title: "Loaders",
      headers: [new Header({ text: "ID", value: "tagID" }), new Header({ text: "Registration", value: "registration" })],
      items: this.$store.getters["storage"]().Loaders as Models.Loader[],
      typename: "Loader",
      onEdit: { routeName: "loader", listUponEditPath: "loader" }
    });
  }

  async rations() {
    this.$store.commit("list/list", {
      title: "Rations",
      headers: [
        new Header({ text: "Ration", value: "name" }),
        new Header({ text: "Description", value: "description" }),
        new Header({ text: "Min Mix Amount", value: "rationMinMix" }),
        new Header({ text: "Min Mixing Time", value: "minMixTime" }),
        new Header({ text: "Use Job Amount", value: "useJobWeightBool" })
      ],
      items: this.$store.getters["storage"]().Rations as Models.Ration[],
      typename: "Ration",
      onEdit: { routeName: "ration", listUponEditPath: "ration" }
    });
  }

  async materials() {
    this.$store.commit("list/list", {
      title: "Materials",
      headers: [
        new Header({ text: "Material", value: "code" }),
        new Header({ text: "Name", value: "name" }),
        new Header({ text: "Description", value: "description" })
      ],
      items: this.$store.getters["storage"]().Materials as Models.Material[],
      typename: "Material",
      onEdit: { routeName: "material", listUponEditPath: "material" }
    });
  }
}
