


















































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import DimssaButton from "@/components/shared/dimssa-button.vue";
import { mapMutations } from "vuex";
import * as Models from "@gigalot/data-models";
import lodash from "lodash";
import {required, minLength, alphaNum } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate'
const vuexMapFields = require("vuex-map-fields");
const mapFields = vuexMapFields.mapFields;
import store from "@/store";

function duplicated(value:string){
  const cm = store.getters["storage"]().material
  const materials = store.getters["storage"]().Materials.filter((m: any) => m.guid !== cm.guid);
  const f = lodash.find(materials,{code:value});
  return f ? false : true;
} 

@Component({
  components: {
    DimssaButton
  },
  mixins: [validationMixin],
  validations : { code: { required, minLength: minLength(3), alphaNum , duplicated },
  name:{required}},
  computed: {
    ...mapFields(["material.code", "material.name", "material.description", "material.manuallyLoaded", "material.active"])
  }
})
export default class Material extends Vue {
changed: boolean = false;

cancel() {
    if (this.changed){
      this.$store.commit("popup/displayYesNo", {
      message: "Discard changes?",
      yesAction: () => {
        this.$store.commit("updateField", {
          path: "material",
          value: {}
        });
        this.$router.go(-1);
      }
    });
    }
    else {
      this.$router.go(-1);
    }
 }

  save() {
    this.$store.commit("popup/displayYesNo", {
      message: "Are you sure you want to save?",
      yesAction: async () => {
        try {
          console.log(this.$store.getters["storage"]().material);
          let materialClone = lodash.cloneDeep(this.$store.getters["storage"]().material);
      
          materialClone.metadata = this.$store.getters["user/getUpstreamMetadata"]();
          let json = await this.$store.dispatch(
            "graphQl",
            {
              gql: `mutation material($guid: String!, $material: MaterialInput!) {
                material(guid: $guid, material: $material)
              }`,
              variables: {
                guid: this.$store.state.user.location.guid,
                material: materialClone
              }
            },
            { root: true }
          );
         
         } catch (err) {
          console.log(err);
           this.$store.commit("popup/displayOk", {
              message: "Error occured while trying to save to server",
            });
          throw err;
        }
        await this.materials();
      }
    });
  }

  async materials(push: boolean = true) {
    try {
      let json = await this.$store.dispatch(
        "graphQl",
        {
          gql: Models.gql.queries.materials,
          variables: { guid: this.$store.state.user.location.guid }
        },
        { root: true }
      );
     
      this.$store.commit("updateField", {
        path: "Materials",
        value: json.data.Materials
      });
    } catch (err) {
      console.log(err);
       this.$store.commit("popup/displayOk", {
            message: `Error: ${err}`
          });
    }
    if (push) this.$router.push({ path: "list/materials" });
  }

  mounted() {}

  destroyed() {}
}
